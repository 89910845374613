import React, { useState, useEffect, Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import InitialImage from "../src/assets/wesafeassets/image/splash_screen_without_text-03.webp";
import { Fade } from "react-reveal";

const RequireAuth = lazy(() => import("./Component/RequireAuth/RequireAuth"));
const AddAnotherProfile = lazy(() =>
  import("./Pages/AddAnotherProfile/AddAnotherProfile")
);
const AllergyForm = lazy(() => import("./Pages/AllergyForm/AllergyForm"));
const Authpage = lazy(() => import("./Pages/AuthPage/Authpage"));
const BuildProfile = lazy(() => import("./Pages/BuildProfile/BuildProfile"));
const EmergencyContactForm = lazy(() =>
  import("./Pages/EmergencyContactForm/EmergencyContactForm")
);
const InsuranceForm = lazy(() => import("./Pages/InsuranceForm/InsuranceForm"));
const MedicationsForm = lazy(() =>
  import("./Pages/MedicationsForm/MedicationsForm")
);
const PersonalProfile = lazy(() =>
  import("./Pages/PersonalProfile/PersonalProfile")
);
const Procedures = lazy(() => import("./Pages/ProceduresForm/Procedures"));
const VaccinationForm = lazy(() =>
  import("./Pages/VaccinationForm/VaccinationForm")
);
const QrActivation = lazy(() => import("./Pages/BuildProfile/QrActivation"));
const NewProfileBuild = lazy(() =>
  import("./Pages/BuildProfile/NewProfileBuild")
);
const DocumentForm = lazy(() => import("./Pages/DocumentForm/DocumentForm"));
const Features = lazy(() => import("./Pages/Features/Features"));
const TakenFeatures = lazy(() => import("./Pages/TakenFeatures/TakenFeatures"));
const MedicalCondiationForm = lazy(() =>
  import("./Pages/MedicalConditonForm/MedicalCondiationForm")
);
const Mainpage = lazy(() => import("./Pages/MainPage/Mainpage"));
const Subscrition = lazy(() => import("./Pages/Subscription/Subscrition"));
const Pack = lazy(() => import("./Pages/Subscription/Pack"));
const Display = lazy(() => import("./Pages/QrDisplay/Display"));

const Privacy = lazy(() => import("./Pages/Documents/Privacy"));
const AppService = lazy(() => import("./Pages/Documents/AppService"));

const Items = lazy(() => import("./Pages/Items/Items"));
const UserItems = lazy(() =>
  import("./Pages/LOST_AND_FOUND/UserItems/UserItems")
);

function App() {
  const [spinner, setSpinner] = useState(true);
  const [Switcheduser, setSwitcheduser] = useState("");
  const [Activeusers, setActiveusers] = useState({});

  useEffect(() => {
    setTimeout(() => setSpinner(false), 1000);
  }, []);

  if (spinner) {
    return (
      <Fade>
        <div className="h-screen">
          <img
            className="h-screen w-screen mx-auto"
            src={InitialImage}
            alt=""
          />
        </div>
        ;
      </Fade>
    );
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Suspense
          fallback={
            <div>
              <img
                className="h-screen w-screen mx-auto"
                src={InitialImage}
                alt=""
              />
            </div>
          }
        >
          <Routes>
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Mainpage
                    setSwitcheduser={setSwitcheduser}
                    Switcheduser={Switcheduser}
                    Activeusers={Activeusers}
                    setActiveusers={setActiveusers}
                  ></Mainpage>
                </RequireAuth>
              }
            />

            <Route path="/auth" element={<Authpage />} />
            <Route
              path="/BuildProfile"
              element={<BuildProfile></BuildProfile>}
            />
            <Route
              path="/qractivation"
              element={<QrActivation></QrActivation>}
            />
            <Route
              path="/newprofilebuild"
              element={<NewProfileBuild></NewProfileBuild>}
            />
            <Route
              path="/personalProfile"
              element={
                <PersonalProfile
                  setSwitcheduser={setSwitcheduser}
                  Switcheduser={Switcheduser}
                  Activeusers={Activeusers}
                  setActiveusers={setActiveusers}
                ></PersonalProfile>
              }
            />

            <Route
              path="/lnfqractivation"
              element={
                <Items
                  setSwitcheduser={setSwitcheduser}
                  Switcheduser={Switcheduser}
                  Activeusers={Activeusers}
                  setActiveusers={setActiveusers}
                ></Items>
              }
            />
            <Route
              path="/lnf/items"
              element={
                <UserItems
                  setSwitcheduser={setSwitcheduser}
                  Switcheduser={Switcheduser}
                  Activeusers={Activeusers}
                  setActiveusers={setActiveusers}
                ></UserItems>
              }
            />

            <Route
              path="/addAnotherProfile"
              element={
                <AddAnotherProfile
                  setSwitcheduser={setSwitcheduser}
                  Switcheduser={Switcheduser}
                  Activeusers={Activeusers}
                  setActiveusers={setActiveusers}
                ></AddAnotherProfile>
              }
            />
            <Route path="/mainpage/:id" element={<Features></Features>} />
            <Route
              path="/emergencycont/details"
              element={
                <EmergencyContactForm
                  Switcheduser={Switcheduser}
                ></EmergencyContactForm>
              }
            />
            <Route
              path="/mediccond/details"
              element={
                <MedicalCondiationForm
                  Switcheduser={Switcheduser}
                ></MedicalCondiationForm>
              }
            />
            <Route
              path="/medications/details"
              element={
                <MedicationsForm Switcheduser={Switcheduser}></MedicationsForm>
              }
            />
            <Route
              path="/allergies/details"
              element={<AllergyForm Switcheduser={Switcheduser}></AllergyForm>}
            />
            <Route
              path="/vaccinations/details"
              element={
                <VaccinationForm Switcheduser={Switcheduser}></VaccinationForm>
              }
            />
            <Route
              path="/procedures/details"
              element={<Procedures Switcheduser={Switcheduser}></Procedures>}
            />
            <Route
              path="/insurance/details"
              element={
                <InsuranceForm Switcheduser={Switcheduser}> </InsuranceForm>
              }
            />
            <Route
              path="/document/details"
              element={
                <DocumentForm Switcheduser={Switcheduser}> </DocumentForm>
              }
            />
            <Route
              path="/subscription/buy"
              element={
                <Subscrition
                  setSwitcheduser={setSwitcheduser}
                  Switcheduser={Switcheduser}
                  Activeusers={Activeusers}
                  setActiveusers={setActiveusers}
                ></Subscrition>
              }
            />
            <Route path="/subscription/pack" element={<Pack></Pack>} />
            <Route
              path="/docs/App-Privacy-Policy-WeSafe"
              element={<Privacy></Privacy>}
            />
            <Route
              path="/docs/App-Service-Agreement-WeSafe"
              element={<AppService></AppService>}
            />
            <Route path="/qr/:id" element={<Display></Display>} />

            <Route
              path="/:id"
              element={
                <TakenFeatures Switcheduser={Switcheduser}></TakenFeatures>
              }
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
